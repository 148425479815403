import React from "react";
import { Button, Navbar, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import CartProduct from "./CartProduct";

function NavbarComponent() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [items, setItems] = useState([]);
    const handleShow = () => {
        setShow(true);
        const cartInfo = localStorage.getItem("cartInfo");
        if (cartInfo) {
            const parsedItems = JSON.parse(cartInfo);
            setItems(parsedItems);
        } else {
            setItems([]);
        }
    };

    const updateCartItems = () => {
        const cartInfo = localStorage.getItem("cartInfo");
        if (cartInfo) {
            const parsedItems = JSON.parse(cartInfo);
            setItems(parsedItems);
        } else {
            setItems([]);
        }
    };

    const checkout = async () => {
        await fetch("https://shop.gallowayhillbillies.org/api/checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ items: items }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.url) {
                    window.location.assign(response.url); // Forwarding user to Stripe
                }
            });
    };

    const [productsCount, setProductsCount] = useState(
        localStorage.getItem("cartAmmount") ? localStorage.getItem("cartAmmount") : 0
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setProductsCount(localStorage.getItem("cartAmmount") ? localStorage.getItem("cartAmmount") : 0);
        }, 30);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Navbar expand="sm">
                <Navbar.Collapse>
                    <button onClick={() => window.location.href = "https://www.gallowayhillbillies.org"}>
                        <svg xmlns="http://www.w3.org/2000/svg"width="15px" style={{marginRight: "10px"}} viewBox="0 0 448 512">
                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                        Back to main website
                    </button>
                </Navbar.Collapse>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end" >
                    <Button onClick={handleShow} className='main-btn'>
                        Cart{" "}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 576 512">
                            <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                        </svg>
                    </Button>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Shopping Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {productsCount > 0 ? (
                        <>
                            <p>Items in your cart:</p>
                            {items.map((currentProduct, idx) => (
                                <CartProduct
                                    key={idx}
                                    id={currentProduct.id}
                                    size={currentProduct.size}
                                    quantity={currentProduct.quantity}
                                    onQuantityChange={updateCartItems}
                                />
                            ))}

                            <h1>
                                Total:{" "}
                                {items
                                    .reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0)
                                    .toFixed(2)}
                            </h1>

                            <Button variant="success" onClick={checkout}>
                                Purchase items!
                            </Button>
                        </>
                    ) : (
                        <h1>There are no items in your cart!</h1>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NavbarComponent;
