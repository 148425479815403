import React, { useState, useEffect } from "react";

function CartProduct(props) {
    const { id, size, onQuantityChange } = props;
    const [productData, setProductData] = useState({});

    useEffect(() => {
        // Get cart info and find the specific item
        const cartInfo = JSON.parse(localStorage.getItem("cartInfo")) || [];
        const item = cartInfo.find((item) => item.id === id && item.size === size);
        const initialData = item || {};
        setProductData(initialData);

        // Only fetch if we have an item
        if (item) {
            fetch("https://shop.gallowayhillbillies.org/api/get-product", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: id }),
            })
                .then((response) => response.json())
                .then((data) => {
                    const updatedData = {
                        ...initialData,
                        price: data.price,
                        title: data.title,
                        quantity: item.quantity,
                        size: item.size,
                    };
                    setProductData(updatedData);

                    // Save price in localStorage
                    const updatedCartInfo = cartInfo.map((cartItem) =>
                        cartItem.id === id && cartItem.size === size
                            ? { ...cartItem, price: data.price }
                            : cartItem
                    );
                    localStorage.setItem("cartInfo", JSON.stringify(updatedCartInfo));
                })
                .catch((error) => console.error("Error fetching product:", error));
        }
    }, [id, size]);

    const removeItem = () => {
        const cartInfo = JSON.parse(localStorage.getItem("cartInfo")) || [];
        const itemIndex = cartInfo.findIndex(
            (item) => item.id === id && item.size === size
        );

        if (itemIndex !== -1) {
            const item = cartInfo[itemIndex];
            localStorage.setItem(
                "cartAmmount",
                localStorage.getItem("cartAmmount") - item.quantity
            );
            cartInfo.splice(itemIndex, 1);
            localStorage.setItem("cartInfo", JSON.stringify(cartInfo));
            setProductData({}); // Trigger re-render
        }
        onQuantityChange(); 
    };

    const decrementQuantity = () => {
        const cartInfo = JSON.parse(localStorage.getItem("cartInfo")) || [];
        const itemIndex = cartInfo.findIndex(
            (item) => item.id === id && item.size === size
        );

        if (itemIndex !== -1) {
            const item = cartInfo[itemIndex];
            if (item.quantity > 1) {
                item.quantity -= 1;
                localStorage.setItem(
                    "cartAmmount",
                    localStorage.getItem("cartAmmount") - 1
                );
            } else {
                cartInfo.splice(itemIndex, 1);
                localStorage.setItem(
                    "cartAmmount",
                    localStorage.getItem("cartAmmount") - 1
                );
            }
            localStorage.setItem("cartInfo", JSON.stringify(cartInfo));
            if (item.quantity < 1) {
                setProductData({}); // Clear product data to remove it from display
            } else {
                setProductData((prevData) => ({
                    ...prevData,
                    quantity: item.quantity,
                }));
            }
            onQuantityChange(); 
        }
    };

    const incrementQuantity = () => {
        const cartInfo = JSON.parse(localStorage.getItem("cartInfo")) || [];
        const itemIndex = cartInfo.findIndex(
            (item) => item.id === id && item.size === size
        );

        if (itemIndex !== -1) {
            const item = cartInfo[itemIndex];
            item.quantity += 1;
            localStorage.setItem(
                "cartAmmount",
                Number(localStorage.getItem("cartAmmount")) + 1
            );
            localStorage.setItem("cartInfo", JSON.stringify(cartInfo));
            setProductData((prevData) => ({
                ...prevData,
                quantity: item.quantity,
            }));
        }
        onQuantityChange(); 
    };

    return (
        <>
            <h3>{productData.title}</h3>
            <p>£{productData.price}</p>
            <p>Size: {size}</p>
            <p>Quantity: {productData.quantity}</p>
            <button type="submit" onClick={decrementQuantity}>
                {" "}
                -{" "}
            </button>
            <button onClick={incrementQuantity}> + </button>
            <br></br>
            <button type="submit" size="sm" onClick={removeItem}>
                Remove
            </button>
            <hr></hr>
        </>
    );
}

export default CartProduct;
