import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Cancel() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("cartAmmount");
        (async () => {
            await fetch("https://shop.gallowayhillbillies.org/api/remove-cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ sessionId: localStorage.getItem("sessionId") }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    console.log(response);
                });
        })();
        localStorage.removeItem("sessionId");
        setTimeout(() => {
            document.title = "Cancel";
            navigate("/");
        }, 3000);
    }, []);
    return (
        <div>
            <h1>Sorry to see you cancelled your purchase</h1>
            <p>You will be redirected to the home page in a moment.</p>
        </div>
    );
}

export default Cancel;
