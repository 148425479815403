import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import NavbarComponent from "../components/Navbar";
import { useState } from "react";
import { useRef } from "react";
import { Alert } from "react-bootstrap";

function Store() {
    const [productsArray, setProductsArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const fixedChildRef = useRef(null);
    const containerRef = useRef(null);

    const updateFixedChildWidth = () => {
        if (containerRef.current && fixedChildRef.current) {
            fixedChildRef.current.style.width = `${containerRef.current.offsetWidth}px`;
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await fetch("https://shop.gallowayhillbillies.org/api/load-items");
            const data = await response.json();

            const formattedData = data.map((product) => ({
                ...product,
                sizes: JSON.parse(product.sizes)
            }));

            const productsArray = formattedData;
            setProductsArray(productsArray);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching products:", error);
            setError("Error fetching products");
            setTimeout(() => {
                updateFixedChildWidth();
            }, 1);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();

        if (localStorage.getItem("sessionIdTime") !== null) {
            const currentTime = new Date().getTime();
            const oldTime = localStorage.getItem("sessionIdTime");
            if (currentTime - oldTime > 1000 * 60 * 60 * 24) {
                localStorage.setItem("sessionId", uuidv4());
                localStorage.setItem("sessionIdTime", new Date().getTime());
            }
        }
        if (localStorage.getItem("sessionId") === null) {
            localStorage.setItem("sessionId", uuidv4());
            localStorage.setItem("sessionIdTime", new Date().getTime());
        }
        console.log(localStorage.getItem("sessionId"));
        console.log(localStorage.getItem("sessionIdTime"));
    }, []);

    return (
        <div ref={containerRef}>
        {error && (
                <Alert
                    ref={fixedChildRef}
                    variant="danger"
                    S
                    onClose={() => setError(false)}
                    style={{ width: "25rem", textAlign: "center" }}
                    dismissible
                >
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{error}</p>
                </Alert>
            )}
        <NavbarComponent></NavbarComponent>
        <h2 align="center" >Tech Shirts and jerseys pre-order by Sunday 10th of Nov</h2>
        <h2 align="center" >to collect from Breakpad</h2>
            <Row xs={1} md={3} className="g-4">
                {productsArray.map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </div >
    );
}

export default Store;