import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [ip, setIpAddress] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getIpAddress = async () => {
        
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            if (response.ok) {
                const data = await response.json();
                setIpAddress(data.ip);
                console.log(data.ip);
            } else {
                throw new Error('Failed to get IP address');
            }
        }
        catch (error) {
            console.error(error);

        }
    }

    const handleLogin = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        getIpAddress();
        try {
            const response = await fetch('https://shop.gallowayhillbillies.org/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, ip })
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('sessionId', data.sessionToken);
                window.location.assign('/admin');
                setIsLoading(false);
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            console.error(error);
            setError('Invalid username or password');
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ width: '300px' }}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        style={{ width: '300px' }}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Button className='main-btn' type="submit">
                    Login
                </Button>
            </Form>
            {isLoading && <span class="loader"></span>}
      {isLoading && <span class="overlay"></span>}
        </div>
    );
};

export default Login;