
import AnniversaryDuathlonHoodie from "./images/10th-Anniversary-Duathlon-Hoodie.jpg";
import AnniversaryDuathlonHoodieBack from "./images/10th-Anniversary-Duathlon-Hoodie-Back.jpg";
import KidsShortSleeveTechShirt from "./images/kidsshortfront.png";
import KidsLongSleeveTechShirt from "./images/kidslongfront.png";
import WomensShortSleeveTechShirt from "./images/womensshortfront.png";
import WomensLongSleeveTechShirt from "./images/womenslongfront.png";
import MensShortSleeveTechShirt from "./images/mensshortfront.png";
import MensLongSleeveTechShirt from "./images/mensfront.png";
import UniSexJersey from "./images/jerseyfront1.png";

const productsArray = [
    {
        id: "price_1OsldJH5Ksq6KYWdM8Ucr8Bw",
        title: "Child 10th Anniversary Duathlon Hoodie",
        price: 18.95,
        image: AnniversaryDuathlonHoodie, AnniversaryDuathlonHoodieBack,
        sizes: ["S1-2 XXS (Chest 24)", "3-4 XS (Chest 26)", "5-6 S (Chest 28)", "7-8 M (Chest 30)", "9-11 L (Chest 32)", "12-13 XL (Chest 34)"]
    },
    {
        id: "price_1OslU2H5Ksq6KYWde8mHQwRF",
        title: "Adult 10th Anniversary Duathlon Hoodie",
        price: 23.95,
        image: AnniversaryDuathlonHoodie, 
        image2: AnniversaryDuathlonHoodieBack,
        image3: KidsShortSleeveTechShirt,
        sizes: ["XS (Chest 34)", "Small (Chest 36)", "Medium (Chest 40)", "Large (Chest 44)", "XL (Chest 48)", "XXL (Chest 52)"]
    },
    {
        id: "price_1P3Pe0H5Ksq6KYWdCU9C5b6n",
        title: "Kids Short Sleeve tech Shirt",
        price: 21.00,
        image: KidsShortSleeveTechShirt,
        sizes: [4-6, 7-8, 9-10, 11-12, 13-14]
    },
    {
        id: "price_1P3PN8H5Ksq6KYWdN6xkPQB2",
        title: "Kids Long Sleeve Tech Shirt",
        price: 21.00,
        image: KidsLongSleeveTechShirt,
        sizes: ["4-6", "7-8", "9-10", "11-12", "13-14"]
    },
    {
        id: "price_1NfKtjH5Ksq6KYWddmw5YXPX",
        title: "Adult Womens Short Sleeve Tech Shirt",
        price: 22.00,
        image: WomensShortSleeveTechShirt,
        sizes: ["Small", "Medium", "Large", "XL"]
    },
    {
        id: "price_1NfKsiH5Ksq6KYWd4C9fsSny",
        title: "Adult Womens Long Sleeve Tech Shirt",
        price: 25.00,
        image: WomensLongSleeveTechShirt,
        sizes: ["Small", "Medium", "Large", "XL"]
    },
    
    {
        id: "price_1NfKv1H5Ksq6KYWd6aTqdQ9E",
        title: "Adult Unisex Short Sleeve Tech Shirt",
        price: 22.00,
        image: MensShortSleeveTechShirt,
        sizes: ["Small", "Medium", "Large", "XL"]
    },
    {
        id: "price_1NfKuaH5Ksq6KYWd7Ytq0ITh",
        title: "Adult Unisex Long Sleeve Tech Shirt",
        price: 25.00,
        image: MensLongSleeveTechShirt,
        sizes: ["Small", "Medium", "Large", "XL"]
    },
    {
        id: "price_1NfKvoH5Ksq6KYWdI3AMgaqM",
        title: "Race Jersey Short Sleeve Unisex",
        price: 55.00,
        image: UniSexJersey,
        sizes: ["XS", "Small", "Medium", "Large", "XL"]
        
    }

];

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };