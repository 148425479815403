import { Card, Button, Form } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";

function ProductCard(props) {
    // props.product is the product we are selling
    const product = props.product;
    const [isOnCooldown, setIsOnCooldown] = useState(false);
    const [selectedSize, setSelectedSize] = useState(product.sizes ? product.sizes[0] : "");

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const addToCart = async () => {
        setIsOnCooldown(true);
        const currentAmount = parseInt(localStorage.getItem('cartAmmount')) || 0;
        localStorage.setItem('cartAmmount', currentAmount + 1);
        
        const cartInfo = JSON.parse(localStorage.getItem('cartInfo')) || [];
        const existingItemIndex = cartInfo.findIndex(item => item.id === product.id && item.size === selectedSize);
        
        if (existingItemIndex !== -1) {
            cartInfo[existingItemIndex].quantity += 1;
        } else {
            cartInfo.push({id: product.id, size: selectedSize, quantity: 1, price: product.price});
        }
        
        localStorage.setItem('cartInfo', JSON.stringify(cartInfo));
        
        setTimeout(() => {
            setIsOnCooldown(false);
        }, 200);
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                {product.image2 && product.image2.length > 0 && (
                    <Carousel className="image-container">
                        <Carousel.Item interval={9999999} >
                            <Card.Img src={product.image} alt="Hoodie Front" className="product-image" />
                        </Carousel.Item>
                        <Carousel.Item interval={9999999} >
                            <Card.Img src={product.image2} alt="Hoodie Back" className="product-image" />
                        </Carousel.Item>
                    </Carousel>
                )}
                {(product.image2.length === 0) && (
                    <div className="image-container">
                        <Card.Img src={`api/${product.image}`} />
                    </div>
                )}
                <Card.Text>£{product.price}</Card.Text>
                {product.sizes && (
                    <Form.Select aria-label="Default select example" onChange={handleSizeChange}>
                        {product.sizes.map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </Form.Select>
                )}

                <Button className="main-btn" onClick={addToCart} disabled={isOnCooldown}>
                    Add To Cart
                </Button>
            </Card.Body>
        </Card>
    );
}

export default ProductCard;